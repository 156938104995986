<template>
  <div class="dashboard">
    <h1>Hello, {{ user.fullName }}!</h1>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
};
</script>
