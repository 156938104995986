<template>
  <div>
    <div class="d-block float-right">
      <v-text-field
        v-model="options.query"
        prepend-icon="mdi-magnify"
        type="text"
        label="Search"
        autocomplete="off"
        clearable
        @input="onSearch($event)"
      ></v-text-field>
    </div>
    <div class="d-block" style="clear: both">
      <UsersList
        v-if="users"
        :loading="loading"
        :users="users"
        :page="options.page"
        :page-size="options.pageSize"
        :total="total"
        @update:options="onPaginationUpdated($event)"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import Vue from 'vue';
import UsersList from './UsersList.vue';

export default {
  name: 'Users',
  components: {
    UsersList,
  },
  data() {
    return {
      loading: false,
      users: [],
      options: {
        page: 1,
        pageSize: 5,
        query: null,
      },
      total: null,
    };
  },
  methods: {
    async fetchUsers(options) {
      if (this.loading) return;

      try {
        this.loading = true;
        const pageResult = await this.$api.user.list({
          ...this.options,
          ...options,
        });
        this.users = pageResult.data;
        this.options = {
          ...this.options,
          page: pageResult.page,
          pageSize: pageResult.pageSize,
        };
        this.total = pageResult.total;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    onPaginationUpdated(pagination) {
      this.options = {
        ...this.options,
        page: pagination.page,
        pageSize: pagination.pageSize,
      };
      this.fetchUsers(this.options);
    },
    onSearch: debounce(function () {
      this.fetchUsers({ page: 1 });
    }, 300),
  },
};
</script>
