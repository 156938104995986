<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="users"
    :items-per-page="pageSize"
    :page="page"
    :options.sync="options"
    :server-items-length="total"
    class="elevation-2"
  ></v-data-table>
</template>

<script>
export default {
  name: 'UsersList',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    page: {
      type: Number,
      default: () => 1,
    },
    pageSize: {
      type: Number,
      default: () => 25,
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Full name',
          value: 'fullName',
        },
        {
          text: 'Active',
          value: 'isActive',
        },
        {
          text: 'Admin',
          value: 'admin',
        },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(value) {
        this.$emit('update:options', {
          page: value?.page || 1,
          pageSize: value?.itemsPerPage || 25,
        });
      },
      deep: true,
    },
  },
};
</script>
